<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>订单管理</a-breadcrumb-item>
        <a-breadcrumb-item>无效订单</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="content-container">
      <a-form :form="form" class="advanced-search-form" @submit="onSearch">
        <a-row class="row">
          <a-col :span="21">
            <a-row>
              <a-col :span="8">
                <a-form-item label="订单编号">
                  <a-input v-decorator="['orderSn']" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="创建时间">
                  <a-range-picker
                    v-decorator="['time']"
                    @change="onChangeTime"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="经销商">
                  <a-input v-decorator="['dealerName']" allow-clear />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="8">
                <a-form-item label="收货人姓名">
                  <a-input v-decorator="['consigneeName']" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="收货人电话">
                  <a-input
                    v-decorator="[
                      'consigneeTel',
                      {
                        rules: [
                          {
                            pattern: /^[0-9]{0,11}$/,
                            message: '手机号格式不正确'
                          }
                        ]
                      }
                    ]"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="订单状态">
                  <a-select
                    v-decorator="['approveFlag', { initialValue: '全部' }]"
                    :options="statusList"
                  />
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="3" class="col">
            <a-button
              type="primary"
              icon="search"
              class="search-button"
              @click="onSearch"
            >
              搜索
            </a-button>
            <a-button icon="undo" class="btn-reset" @click="onReset">
              重置
            </a-button>
          </a-col>
        </a-row>
      </a-form>
      <div class="main-content">
        <a-table
          :data-source="tblData"
          :columns="tblColumns"
          :pagination="pagination"
          :loading="loading"
          :row-key="
            (record, index) => {
              return index;
            }
          "
          @change="onPage"
        >
          <template slot="dealerName" slot-scope="text, record">
            <div>
              <div>{{ record.dealerName }}</div>
              <div class="dealer-type">
                {{ record.dealerType
                }}{{ record.storeType ? "(" + record.storeType + ")" : "" }}
              </div>
            </div>
          </template>
          <template slot="action" slot-scope="text, record">
            <a @click="onDetail(record.orderSn)">查看详情</a>
          </template>
        </a-table>
      </div>
    </div>
  </a-layout-content>
</template>

<script>
import { queryInvalidOrderList } from "../../services/OrderService";
import { tsFormat } from "../../components/DateUtils";
// import moment from "moment";

const tblColumns = [
  {
    title: "订单编号",
    dataIndex: "orderSn"
  },
  {
    title: "创建时间",
    dataIndex: "createTime"
  },
  {
    title: "经销商",
    dataIndex: "dealerName",
    scopedSlots: { customRender: "dealerName" }
  },
  {
    title: "收货人姓名",
    dataIndex: "consigneeName"
  },
  {
    title: "收货人电话",
    dataIndex: "consigneeTel"
  },
  {
    title: "订单状态",
    dataIndex: "approveFlag"
  },

  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];
export default {
  data() {
    return {
      form: this.$form.createForm(this),
      tblData: [],
      tblColumns,
      pagination: {
        hideOnSinglePage: true
      },
      loading: false,
      startTime: "",
      endTime: "",
      statusList: [
        { value: "全部", label: "全部" },
        { value: "订单取消", label: "订单取消" },
        { value: "驳回", label: "驳回" }
      ]
    };
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    onSearch() {
      this.pagination.current = 1;
      this.fetchList();
    },
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.fetchList();
    },
    onDetail(orderSn) {
      this.$router.push({
        name: "InvalidDetail",
        params: { orderSn: orderSn }
      });
    },
    fetchList() {
      const params = {
        ...this.form.getFieldsValue(),
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10,
        startTime: this.startTime,
        endTime: this.endTime,
        factoryId: localStorage.getItem("factoryId")
      };
      queryInvalidOrderList(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const records = resp.data.data.records;
          const data = resp.data.data;
          const pagination = { ...this.pagination };
          pagination.current = data.pageNum;
          pagination.total = data.total;
          pagination.pageSize = data.pageSize;
          pagination.showTotal = function(total, range) {
            return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
          };
          this.pagination = pagination;
          if (records.length > 0) {
            records.forEach(item => {
              item.createTime = tsFormat(item.createTime);
              if (item.cancelFlag) {
                item.approveFlag = "订单取消";
              } else {
                item.approveFlag = "驳回";
              }
            });
          }
          this.tblData = records;
        }
      });
    },
    onChangeTime(date, dateString) {
      if (dateString && dateString.length > 0) {
        this.startTime = dateString[0];
        this.endTime = dateString[1];
      }
    },
    onReset() {
      this.form.resetFields();
      this.startTime = "";
      this.endTime = "";
      this.fetchList();
    }
  }
};
</script>

<style scoped>
.content-container {
  min-height: 1000px;
  background-color: white;
  margin: 30px;
}
/deep/ .advanced-search-form .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
}
/deep/ .ant-form-item .ant-form-item-label {
  text-align: right;
  min-width: 100px;
}
.row {
  display: flex;
}
.col {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}
.btn-reset {
  margin-bottom: 8px;
}
.dealer-type {
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
}
</style>
