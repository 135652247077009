<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>订单管理</a-breadcrumb-item>
        <a-breadcrumb-item><a @click="onBack">无效订单</a></a-breadcrumb-item>
        <a-breadcrumb-item>订单详情</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <section class="info-container">
      <a-row>
        <a-col><div class="info-title">基本信息</div></a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-form :form="infoForm">
            <a-row style="display: flex;flex-wrap: wrap">
              <a-col :span="8">
                <a-form-item label="订单编码" class="form-item">
                  {{ info.orderSn }}
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="订单类型" class="form-item">
                  {{ info.orderType }}
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="下单时间" class="form-item">
                  {{ tsFormat(info.createTime) }}
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="经销商" class="form-item">
                  {{ info.businessVenuName }}
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="是否全款" class="form-item">
                  {{ info.fullPaymentFlag ? "全款支付" : "分期支付" }}
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="商品总价" class="form-item">
                  {{ info.totalPayment ? "¥" + info.totalPayment : "--" }}
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item
                  v-if="!info.cancelFlag"
                  label="订单状态"
                  class="form-item"
                >
                  {{ info.approveStatus }}
                </a-form-item>
                <a-form-item v-else label="订单状态" class="form-item">
                  订单取消
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="订单关闭时间" class="form-item">
                  {{ info.modifyTime ? tsFormat(info.modifyTime) : "" }}
                </a-form-item>
              </a-col>
              <a-col v-if="info.approveStatus === '被驳回'" :span="8">
                <a-form-item label="驳回原因" class="form-item">
                  {{ info.dismissReason ? info.dismissReason : "--" }}
                </a-form-item>
              </a-col>
              <a-col v-if="info.orderType === '远期订单'" :span="8">
                <a-form-item label="下订月份" class="form-item">
                  {{ formatMonth(info.monthOrderSkuDOList) }}
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <a-form-item label="订单备注" class="form-item">
                  <div>{{ info.memo ? info.memo : "-" }}</div>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </a-col>
      </a-row>
    </section>
    <section class="receive-info">
      <div class="info-title">收货信息</div>
      <a-form :form="receiveForm">
        <a-row>
          <a-col :span="8">
            <a-form-item label="收货人姓名" class="form-item">
              {{ info.consigneeName }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收货人电话" class="form-item">
              {{ info.consigneeTel }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收货地址" class="form-item">
              {{ info.consigneeFullAddress }}
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="commodity-info">
      <div class="commodity-title">
        <div class="info-title">商品信息</div>
      </div>
      <section v-for="(item, index) in dataList" :key="index">
        <a-table
          class="commodity-table"
          :data-source="item.list"
          :columns="tableColumns"
          :pagination="false"
          :loading="loading"
          row-key="skuId"
        >
          <div
            slot="skuImages"
            v-viewer
            slot-scope="text, record"
            class="img-container"
          >
            <div
              v-if="record.fileList && record.fileList.length > 0"
              class="img-container"
            >
              <img
                v-if="!record.fileList[0].thumbUrl"
                :src="imageBaseUrl + record.fileList[0].fileUrl"
                :data-source="imageBaseUrl + record.fileList[0].fileUrl"
                alt="产品图片"
                class="img-sku"
              />
              <img
                v-else
                :src="imageBaseUrl + record.fileList[0].thumbUrl"
                :data-source="imageBaseUrl + record.fileList[0].fileUrl"
                alt="产品图片"
                class="img-sku"
              />
            </div>
          </div>
          <template v-if="info.orderType !== '即时订单'" slot="title">
            {{ item.month }}
          </template>
        </a-table>
      </section>
    </section>
    <section class="bottom-holder"></section>
  </a-layout-content>
</template>

<script>
import { fetchUndeliveredDetail } from "../../services/OrderService";
import { tsFormat } from "../../components/DateUtils";
import { imageBaseUrl } from "../../services/HttpService";
import { formatList, toChinesNum } from "../../components/utils";

const tableColumns = [
  {
    title: "商品编码",
    dataIndex: "skuCode"
  },
  {
    title: "商品名称",
    dataIndex: "skuName"
  },

  {
    title: "商品图",
    dataIndex: "skuImages",
    scopedSlots: { customRender: "skuImages" }
  },
  {
    title: "商品数量",
    dataIndex: "purchaseQuantity"
  },
  {
    title: "已发货数量",
    dataIndex: "deliveryQuantity"
  },
  {
    title: "装运总体积（m³）",
    dataIndex: "skuVolumeTotal"
  },
  {
    title: "装运总质量（kg）",
    dataIndex: "skuWeightTotal"
  }
];
export default {
  data() {
    return {
      tsFormat,
      formatList,
      imageBaseUrl,
      toChinesNum,
      infoForm: this.$form.createForm(this),
      receiveForm: this.$form.createForm(this),
      tblData: [],
      tableColumns,
      pagination: {
        hideOnSinglePage: true
      },
      step: 0,
      loading: false,
      isImportValue: false,
      firstButton: "驳回",
      secondButton: "通过",
      price: "",
      payWay: "",
      calculationForm: this.$form.createForm(this),
      orderSn: this.$route.params.orderSn,
      info: {},
      setStandardVisible: false,
      purchaseMinVolume: "",
      purchaseMaxVolume: "",
      purchaseMinWeight: "",
      purchaseMaxWeight: "",
      dateList: [],
      orderSkuList: [],
      firstPay: "",
      firstPayInfo: {},
      factoryId: localStorage.getItem("factoryId"),
      dataList: []
    };
  },
  mounted() {
    this.onDetail();
  },
  methods: {
    onPage(pagination) {
      this.pagination.current = pagination.current;
    },
    formatMonth(form) {
      let str = "";
      for (const key in form) {
        str += `${key}`;
      }
      return str;
    },
    onDetail() {
      this.loading = true;
      fetchUndeliveredDetail(this.orderSn).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.loading = false;
          const data = resp.data.data;
          this.info = data;
          this.dataList = [];
          let skuList = [];
          for (const key in data.monthOrderSkuDOList) {
            skuList = data.monthOrderSkuDOList[key];
            this.dataList.push({ month: key, list: skuList });
          }
          skuList.forEach(item => {
            item.skuVolumeTotal = item.skuVolume * item.purchaseQuantity;
            item.skuWeightTotal = item.skuWeight * item.purchaseQuantity;
          });
        }
      });
    },
    onBack() {
      this.$router.push({ name: "InvalidOrder" });
    }
  }
};
</script>

<style scoped>
.examine-container {
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
}
.button-container {
  display: flex;
}
.button-item {
  margin-right: 15px;
}
.step-container {
  background-color: white;
  margin: 10px 20px;
  box-sizing: border-box;
  padding: 25px 10px 25px 10px;
}
/deep/ .ant-steps-dot .ant-steps-item-title,
.ant-steps-dot.ant-steps-small .ant-steps-item-title {
  min-width: 180px;
}
/deep/ .ant-steps-dot .ant-steps-item-tail,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  margin: 0 0 0 90px;
}
/deep/ .ant-steps-dot .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  margin-left: 87px;
}
.info-container {
  background-color: white;
  margin: 10px 20px;
  box-sizing: border-box;
  padding: 25px 30px 25px 30px;
}
.info-title {
  font-weight: bold;
  margin-bottom: 10px;
}
.ant-row {
  display: flex !important;
}
/deep/ .ant-form-item .ant-form-item-label {
  text-align: left !important;
  min-width: 80px !important;
}
/deep/ .form-item .ant-form-item-control {
  width: 90% !important;
}
.info-container /deep/ .ant-form-item {
  margin: 0;
}
.receive-info {
  background-color: white;
  margin: 10px 20px;
  box-sizing: border-box;
  padding: 25px 30px;
}
.commodity-info {
  background-color: white;
  margin: 10px 20px 10px 20px;
  box-sizing: border-box;
  padding: 25px 30px;
}
.commodity-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.commodity-table {
  margin-top: 20px;
  margin-bottom: 20px;
}
.bottom-holder {
  width: 100%;
  height: 70px;
}
.price-title {
  min-width: 80px;
}
.col {
  display: flex;
  align-items: center;
}
.finish {
  color: #3769ff;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 80px 0;
}
.finish img {
  margin-bottom: 20px;
}
.finish span {
  margin-left: 18px;
}
/deep/ .ant-table-title {
  display: flex;
  justify-content: center;
  background-color: rgba(55, 105, 255, 0.1);
}
/deep/ .advanced-search-form .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
}
/deep/ .ant-form-item .ant-form-item-label {
  text-align: right;
  min-width: 120px !important;
}
/deep/ .ant-form-item-control {
  width: 90%;
}
.img-container {
  width: 100px;
  height: 100px;
}
.img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.set-standard-container {
  display: flex;
  align-items: flex-end;
}
.input-standard {
  width: 100px;
  margin-right: 10px;
}
.standard-word {
  min-width: 120px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}
.set-standard-container-two {
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
}
.unit {
  width: 20px;
}
.img-sku {
  width: 100px;
  height: 100px;
}
.table-item {
  box-sizing: border-box;
  padding-bottom: 20px;
}
</style>
